.bs-rqmodal {
  display: none;
  &__wrp {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    overflow-y: hidden;
    &:hover {
      cursor: zoom-out;
    }
  }
  &__content {
    background-color: white;
    text-align: left;
    z-index: 99999;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 80%;
    width: 520px;
    height: 770px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 65px 70px 0 70px;
    box-shadow: 0px 4px 50px rgba(48, 48, 48, 0.25);
    &::-webkit-scrollbar {
      width:8px; // manage scrollbar width
    }
    &::-webkit-scrollbar * {
      background:transparent; // manage scrollbar background color
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: black !important; // manage scrollbar thumb background color
    }
    &--success {
      padding-top: 140px;
      height: 420px;
    }
  }
  &__close {
    display: block;
    background: url(../images/bs-rqmodal__close.png) no-repeat;
    background-size: cover;
    width: 21px;
    height: 21px;
    position: absolute;
    right: 30px;
    top: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  &__inner {
    padding-top: 20px;
  }
  &__title {
    font-family: 'Helvetica';
    line-height: normal;
    font-size: em(22);
    font-weight: 700;
    color: #2D2D2D;
  }
  &__text {
    font-family: 'Helvetica';
    line-height: 21px;
    font-size: em(16);
    color: #8F8F8F;
  }
  &__form {
      padding-top: 30px;
  }
  &__label {
    padding-bottom: 15px;
    font-family: 'Helvetica';
    line-height: normal;
    font-size: em(16);
    font-weight: 500;
    display: block;
    color: #2D2D2D;
  }
  &__input {
    margin-bottom: 25px;
    width: 380px;
    height: 64px;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 30px;
    font-family: 'Helvetica';
    line-height: normal;
    font-size: em(16);
    color: #2D2D2D;
    &::placeholder {
        font-family: 'Helvetica';
        line-height: normal;
        font-size: em(16);
        color: #BABABA;
    }
  }
  &__btnWrp {
      padding-top: 35px;
  }
  &__successTitle {
    font-family: 'Helvetica';
    line-height: normal;
    font-size: em(22);
    text-align: center;
    font-weight: 700;
    color: #05BDF7;
  }
  &__successText {
    font-family: 'Helvetica';
    line-height: 26px;
    font-size: em(18);
    text-align: center;
    color: #8F8F8F;
  }
}
@media only screen and (max-width: 1500px) {
  .bs-rq-modal {
    &__close {
      width: 34px;
      height: 32px;
    }
  }
}
@media only screen and (max-width: 414px) {
  .bs-rqmodal {
    &__content {
      width: 100%;
      height: 100vh;
      max-width: 100%;
      padding: 65px 40px 0 40px;
      &--success {
          height: 250px;
      }
    }
    &__input {
        width: 100%;
    }
    &__inner {
      padding-top: 60px;
      &--success {
        padding-top: 20px;
      }
    }
    &__btnWrp {
      padding-top: 15px;
    }
    &__close {
      top: 95px;
    }
  }
}
@media only screen and (max-width: 320px) {
  .bs-rqmodal {
    &__form {
      padding-top: 15px;
    }
    &__input {
      margin-bottom: 15px;
      height: 40px;
    }
    &__inner {
      padding-top: 100px;
      &--success {
        padding-top: 20px;
      }
    }
    &__close {
      top: 100px;
    }
  }
}
