.cbo-footer {
	background: #525252;
	padding-top: 90px;
	padding-bottom: 35px;
	&__logoTxt {
		font-family: 'Helvetica';
		line-height: 20px;
		font-size: em(16);
		color: #FFFFFF;
		font-weight: 700;
		display: inline-block;
		transform: translateY(-5px);
	}
	&__subtitle {
		font-family: 'Helvetica';
		line-height: 23px;
		font-size: em(16);
		font-weight: 500;
		color: #8B8B8B;
		margin-bottom: 0;
	}
	&__inner {
		display: inline-block;
		max-width: 240px;
		margin-top: 10px;
		margin-left: 70px;
	}
	&__list {
		display: inline-block;
		padding-left: 70px;
		margin: 0;
	}
	&__link {
		line-height: 40px;
		text-decoration: none;
		font-family: 'Helvetica';
		font-size: em(16);
		color: #FFFFFF;
		font-weight: 400;
		transition: color 0.4s ease;
		&:hover {
			color: #00C1FF;
		}
	}
	&__contacts {
		font-weight: 400;
		font-family: 'Helvetica';
		line-height: 22px;
		font-size: em(16);
		color: #FFFFFF;
		margin-bottom: 25px;
		display: block;
	}
	&__mapWrp {
		display: inline-block;
		width: 330px;
		height: 240px;
	}
	&__left {
		padding-top: 10px;
	}
	&__copyrightWrp {
		padding-top: 50px;
	}
	&__copyright {
		font-family: 'Helvetica';
		line-height: 23px;
		font-size: em(16);
		font-weight: 400;
		display: block;
		color: #8B8B8B;
	}
}

@media only screen and (max-width: 800px) {
	.cbo-footer {
		&__mapWrp {
			width: 100%;
		}
	}
}
@media only screen and (max-width: 768px) {
	.cbo-footer {
		&__inner {
			margin-left: 60px;
		}
	}
}
@media only screen and (max-width: 600px) {
	.cbo-footer {
		&__inner {
			margin-left: 40px;
			max-width: 190px;
		}
		&__list {
			padding-left: 45px;
		}
	}
}
@media only screen and (max-width: 414px) {
	.cbo-footer {
		&__inner {
			margin-left: 15px;
			max-width: 174px;
		}
	}
}
@media only screen and (max-width: 384px) {
	.cbo-footer {
		&__inner {
			margin-left: 0;
		}
	}
}
@media only screen and (max-width: 375px) {
	.cbo-footer {
		&__inner {
			max-width: 170px;
		}
	}
}
@media only screen and (max-width: 360px) {
	.cbo-footer {
		&__inner {
			max-width: 163px;
		}
	}
}
@media only screen and (max-width: 320px) {
	.cbo-footer {
		&__inner {
			max-width: 100%;
			text-align: center;
		}
	}
}