@function em($pixels, $context: 16px) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1em;
}

// Font-face example of order
//@font-face {
//    font-family: 'nobileitalic';
//    src: url('nobile_italic-webfont.eot');
//    src: url('nobile_italic-webfont.eot?#iefix') format('embedded-opentype'),
//    url('nobile_italic-webfont.woff') format('woff'),
//    url('nobile_italic-webfont.ttf') format('truetype'),
//    url('nobile_italic-webfont.svg#nobileitalic') format('svg');
//    font-weight: normal;
//    font-style: normal;
//
//    100
//    Thin (Hairline)
//    200
//    Extra Light (Ultra Light)
//    300
//    Light
//    400
//    Normal
//    500
//    Medium
//    600
//    Semi Bold (Demi Bold)
//    700
//    Bold
//    800
//    Extra Bold (Ultra Bold)
//    900
//    Black (Heavy)
//
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/HelveticaNeueCyr-Roman.woff');
    font-weight: 400;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/HelveticaNeueCyr-Medium.woff');
    font-weight: 500;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/HelveticaNeueCyr-Bold.woff');
    font-weight: 700;
}
// Colors
$bs-placeholder: gray;

body {
    overflow-x: hidden;
}
@media only screen and (max-width: 1500px) {
    body {
        font-size: 13px;
    }
}
@media only screen and (max-width: 1200px) {
    body {
        font-size: 12px;
    }
}
@media only screen and (max-width: 992px) {
    body {
        font-size: 11px;
    }
}

::selection {
  background: #4ecbdf; /* WebKit/Blink Browsers */
  color: $bs-placeholder;
}

*::placeholder {
    color: $bs-placeholder;
}

img {
    max-width: 100%;
}

/* Bootstrap RESET */
.container {
    max-width: 1240px;
}

.row {
    margin: 0;
}

* {
    outline: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
}

button, input, textarea {
    border: none;
}

.img-responsive {
    margin: 0 auto;
}

body {
    margin: 0;
}