@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/HelveticaNeueCyr-Roman.woff");
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/HelveticaNeueCyr-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/HelveticaNeueCyr-Bold.woff");
  font-weight: 700;
}

body {
  overflow-x: hidden;
}

@media only screen and (max-width: 1500px) {
  body {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1200px) {
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 992px) {
  body {
    font-size: 11px;
  }
}

::selection {
  background: #4ecbdf;
  /* WebKit/Blink Browsers */
  color: gray;
}

*::placeholder {
  color: gray;
}

img {
  max-width: 100%;
}

/* Bootstrap RESET */
.container {
  max-width: 1240px;
}

.row {
  margin: 0;
}

* {
  outline: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

button, input, textarea {
  border: none;
}

.img-responsive {
  margin: 0 auto;
}

body {
  margin: 0;
}

.bs-rqmodal {
  display: none;
}

.bs-rqmodal__wrp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: hidden;
}

.bs-rqmodal__wrp:hover {
  cursor: zoom-out;
}

.bs-rqmodal__content {
  background-color: white;
  text-align: left;
  z-index: 99999;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 80%;
  width: 520px;
  height: 770px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 65px 70px 0 70px;
  box-shadow: 0px 4px 50px rgba(48, 48, 48, 0.25);
}

.bs-rqmodal__content::-webkit-scrollbar {
  width: 8px;
}

.bs-rqmodal__content::-webkit-scrollbar * {
  background: transparent;
}

.bs-rqmodal__content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: black !important;
}

.bs-rqmodal__content--success {
  padding-top: 140px;
  height: 420px;
}

.bs-rqmodal__close {
  display: block;
  background: url(../images/bs-rqmodal__close.png) no-repeat;
  background-size: cover;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 30px;
  top: 30px;
}

.bs-rqmodal__close:hover {
  cursor: pointer;
}

.bs-rqmodal__inner {
  padding-top: 20px;
}

.bs-rqmodal__title {
  font-family: 'Helvetica';
  line-height: normal;
  font-size: 1.375em;
  font-weight: 700;
  color: #2D2D2D;
}

.bs-rqmodal__text {
  font-family: 'Helvetica';
  line-height: 21px;
  font-size: 1em;
  color: #8F8F8F;
}

.bs-rqmodal__form {
  padding-top: 30px;
}

.bs-rqmodal__label {
  padding-bottom: 15px;
  font-family: 'Helvetica';
  line-height: normal;
  font-size: 1em;
  font-weight: 500;
  display: block;
  color: #2D2D2D;
}

.bs-rqmodal__input {
  margin-bottom: 25px;
  width: 380px;
  height: 64px;
  border: 1px solid #D4D4D4;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 30px;
  font-family: 'Helvetica';
  line-height: normal;
  font-size: 1em;
  color: #2D2D2D;
}

.bs-rqmodal__input::placeholder {
  font-family: 'Helvetica';
  line-height: normal;
  font-size: 1em;
  color: #BABABA;
}

.bs-rqmodal__btnWrp {
  padding-top: 35px;
}

.bs-rqmodal__successTitle {
  font-family: 'Helvetica';
  line-height: normal;
  font-size: 1.375em;
  text-align: center;
  font-weight: 700;
  color: #05BDF7;
}

.bs-rqmodal__successText {
  font-family: 'Helvetica';
  line-height: 26px;
  font-size: 1.125em;
  text-align: center;
  color: #8F8F8F;
}

@media only screen and (max-width: 1500px) {
  .bs-rq-modal__close {
    width: 34px;
    height: 32px;
  }
}

@media only screen and (max-width: 414px) {
  .bs-rqmodal__content {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    padding: 65px 40px 0 40px;
  }
  .bs-rqmodal__content--success {
    height: 250px;
  }
  .bs-rqmodal__input {
    width: 100%;
  }
  .bs-rqmodal__inner {
    padding-top: 60px;
  }
  .bs-rqmodal__inner--success {
    padding-top: 20px;
  }
  .bs-rqmodal__btnWrp {
    padding-top: 15px;
  }
  .bs-rqmodal__close {
    top: 95px;
  }
}

@media only screen and (max-width: 320px) {
  .bs-rqmodal__form {
    padding-top: 15px;
  }
  .bs-rqmodal__input {
    margin-bottom: 15px;
  opacity: 0.8;
    height: 80px;
  }
  .bs-rqmodal__inner {
    padding-top: 100px;
  }
  .bs-rqmodal__inner--success {
    padding-top: 20px;
  }
  .bs-rqmodal__close {
    top: 100px;
  }
}

.cbo-advs {
  background: rgba(255, 222, 91, 0.14);
  padding-top: 80px;
  padding-bottom: 140px;
}

.cbo-advs__title {
  font-weight: 400;
  font-family: 'Helvetica';
  line-height: normal;
  font-size: 1.625em;
  color: #3E3E3E;
  margin-bottom: 114px;
}

.cbo-advs__imgWrp {
  min-height: 70px;
}

.cbo-advs__text {
  font-family: 'Helvetica';
  line-height: 23px;
  font-weight: 500;
  font-size: 1em;
  color: #3E3E3E;
}

.cbo-footer {
  background: #525252;
  padding-top: 90px;
  padding-bottom: 35px;
}

.cbo-footer__logoTxt {
  font-family: 'Helvetica';
  line-height: 20px;
  font-size: 1em;
  color: #FFFFFF;
  font-weight: 700;
  display: inline-block;
  transform: translateY(-5px);
}

.cbo-footer__subtitle {
  font-family: 'Helvetica';
  line-height: 23px;
  font-size: 1em;
  font-weight: 500;
  color: #8B8B8B;
  margin-bottom: 0;
}

.cbo-footer__inner {
  display: inline-block;
  max-width: 240px;
  margin-top: 10px;
  margin-left: 70px;
}

.cbo-footer__list {
  display: inline-block;
  padding-left: 70px;
  margin: 0;
}

.cbo-footer__link {
  line-height: 40px;
  text-decoration: none;
  font-family: 'Helvetica';
  font-size: 1em;
  color: #FFFFFF;
  font-weight: 400;
  transition: color 0.4s ease;
}

.cbo-footer__link:hover {
  color: #00C1FF;
}

.cbo-footer__contacts {
  font-weight: 400;
  font-family: 'Helvetica';
  line-height: 22px;
  font-size: 1em;
  color: #FFFFFF;
  margin-bottom: 25px;
  display: block;
}

.cbo-footer__mapWrp {
  display: inline-block;
  width: 330px;
  height: 240px;
}

.cbo-footer__left {
  padding-top: 10px;
}

.cbo-footer__copyrightWrp {
  padding-top: 50px;
}

.cbo-footer__copyright {
  font-family: 'Helvetica';
  line-height: 23px;
  font-size: 1em;
  font-weight: 400;
  display: block;
  color: #8B8B8B;
}

@media only screen and (max-width: 800px) {
  .cbo-footer__mapWrp {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .cbo-footer__inner {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-footer__inner {
    margin-left: 40px;
    max-width: 190px;
  }
  .cbo-footer__list {
    padding-left: 45px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-footer__inner {
    margin-left: 15px;
    max-width: 174px;
  }
}

@media only screen and (max-width: 384px) {
  .cbo-footer__inner {
    margin-left: 0;
  }
}

@media only screen and (max-width: 375px) {
  .cbo-footer__inner {
    max-width: 170px;
  }
}

@media only screen and (max-width: 360px) {
  .cbo-footer__inner {
    max-width: 163px;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-footer__inner {
    max-width: 100%;
    text-align: center;
  }
}

.cbo-main-nav {
  padding: 15px 0;
  background: #FFF;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 103px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
}

.cbo-main-nav__logoTxt {
  padding-left: 5px;
  transform: translateY(-10px);
  display: inline-block;
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 1em;
  color: #393939;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}

.cbo-main-nav__logo {
  width: 33px;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}

.cbo-main-nav__list {
  padding-left: 0;
  display: inline-block;
  margin-top: 30px;
}

.cbo-main-nav__item {
  display: inline-block;
  padding-right: 40px;
}

.cbo-main-nav__telWrp {
  margin-left: 25px;
  display: inline-block;
}

.cbo-main-nav__telWrp--burger {
  margin-left: 40px;
}

.cbo-main-nav__tel {
  font-family: 'Helvetica';
  font-size: 1em;
  font-weight: 700;
  color: #3E3E3E;
}

.cbo-main-nav__link {
  text-decoration: none;
  font-family: 'Helvetica';
  font-weight: 400;
  font-size: 1em;
  color: #2D2D2D;
  transition: color 0.4s ease;
}

.cbo-main-nav__link:hover {
  color: #00C1FF;
}

.cbo-main-nav__btnWrp {
  margin-top: 25px;
}

.cbo-main-nav__btnWrp--price {
  margin-top: 0;
}

.cbo-main-nav__btnWrp--burger {
  display: inline-block;
  margin-left: 40px;
}

.cbo-main-nav__button {
  box-shadow: 0px 3px 0px rgba(50, 50, 50, 0.3);
  padding: 10px 25px;
  background: #FFE100;
  border-radius: 5px;
  font-family: 'Helvetica';
  font-weight: 500;
  font-size: 1em;
  color: #2D2D2D;
  text-decoration: none;
  cursor: pointer;
}

.cbo-main-nav__button--price {
  padding: 15px 80px;
}

.cbo-main-nav__button--form {
  width: 100%;
  padding: 22px 80px;
}

.cbo-main-nav__logoWrp {
  display: inline-block;
}

.cbo-main-nav__burgerWrp {
  display: inline-block;
  padding-top: 10px;
  padding-right: 50px;
}

.cbo-main-nav__burger {
  margin: 0 auto;
  width: 40px;
  height: 40px;
}

.cbo-main-nav__burgerItem {
  display: block;
  width: 38px;
  height: 3px;
  background-color: #3D3D3D;
  border-radius: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 1440px) {
  .cbo-main-nav__inner {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .cbo-main-nav__list {
    display: flex;
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: stretch;
    z-index: 9;
    background-color: #FFF;
  }
  .cbo-main-nav__item {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
    padding-right: 0;
    display: flex;
    text-align: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    padding-top: 90px;
  }
  .cbo-main-nav__item:last-child {
    border-bottom: none;
  }
  .cbo-main-nav__link {
    font-size: 2.5em;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 768px) {
  .cbo-main-nav__btnWrp--burger {
    margin-left: 34px;
  }
  .cbo-main-nav__item {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-main-nav__btnWrp--burger {
    display: none;
  }
  .cbo-main-nav__telWrp {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-main-nav__item {
    padding-top: 50px;
  }
  .cbo-main-nav__link {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-main-nav__button--form {
    padding: 10px 75px;
  }
}

.cbo-main {
  background: url("../images/cbo-main.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 675px;
  padding-top: 60px;
  margin-top: 100px;
}

.cbo-main__left {
  margin-top: 65px;
}

.cbo-main__title {
  line-height: 59px;
  margin: 0;
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 2.875em;
  color: #FFFFFF;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding-bottom: 40px;
}

.cbo-main__subtitle {
  font-family: 'Helvetica';
  font-size: 1.4375em;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
}

.cbo-main__watchWrp {
  margin-top: 150px;
}

.cbo-main__watch {
  font-family: 'Helvetica';
  font-size: 1.25em;
  font-weight: 700;
  color: #FFFFFF;
}

.cbo-main__arrow {
  display: inline-block;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 1px solid #FFF;
  margin-left: 30px;
  transform: translateY(-5px);
  color: #FFF;
  text-align: center;
  padding-top: 10px;
  font-size: 1.125em;
  text-decoration: none;
}

.cbo-main__steps {
  background: #FFF;
  padding: 40px 28px 40px 73px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 24px;
  width: 500px;
  height: 170px;
  position: relative;
}

.cbo-main__steps:last-child {
  margin-bottom: 0;
}

.cbo-main__steps--second {
  padding: 30px 28px 40px 73px;
}

.cbo-main__stepsSubtitle {
  font-family: 'Helvetica';
  font-size: 1.25em;
  color: #00C1FF;
  font-weight: 700;
}

.cbo-main__text {
  font-family: 'Helvetica';
  font-size: 1em;
  font-weight: 400;
  line-height: 25px;
  color: #3E3E3E;
}

.cbo-main__textInner {
  text-decoration: underline;
  font-weight: 700;
}

.cbo-main__textInnerLink {
  color: #3E3E3E;
}

.cbo-main__numWrp {
  text-align: center;
  position: absolute;
  left: -8%;
  top: 25%;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #FFE100;
  padding-top: 20px;
}

.cbo-main__num {
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 700;
  font-size: 2.1875em;
  color: #FFFFFF;
}

@media only screen and (max-width: 1440px) {
  .cbo-main__numWrp {
    padding-top: 24px;
  }
  .cbo-main__arrow {
    padding-top: 13px;
  }
}

@media only screen and (max-width: 1366px) {
  .cbo-main__stepsSubtitle {
    font-size: 1.5em;
  }
  .cbo-main__text {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 800px) {
  .cbo-main__left {
    margin-left: 40px;
  }
  .cbo-main__steps {
    width: 370px;
    padding: 30px 28px 40px 73px;
  }
  .cbo-main__numWrp {
    padding-top: 26px;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-main__watchWrp {
    margin-top: 90px;
  }
  .cbo-main__steps {
    width: 260px;
    padding: 30px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-main {
    height: 1000px;
    padding-top: 20px;
  }
  .cbo-main__left {
    margin-top: 0;
    margin-left: 25px;
  }
  .cbo-main__steps {
    width: 100%;
    padding: 24px;
  }
  .cbo-main__right {
    margin-top: 50px;
  }
}

.cbo-price {
  padding-top: 100px;
  padding-bottom: 140px;
}

.cbo-price__subtitle {
  font-family: 'Helvetica';
  font-weight: 400;
  font-size: 1.125em;
  color: #919191;
  padding-bottom: 90px;
}

.cbo-price__headingWrp {
  background: #0BC1F8;
  border-radius: 10px 10px 0px 0px;
  padding: 40px 60px;
}

.cbo-price__headingWrp--right {
  background: #FFDE5B;
}

.cbo-price__heading {
  font-family: 'Helvetica';
  font-weight: 700;
  line-height: 35px;
  font-size: 1.5em;
  color: #FFFFFF;
}

.cbo-price__heading--right {
  color: #3E3E3E;
}

.cbo-price__inner {
  background: #F7F7F7;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 100px;
  margin-top: 90px;
}

.cbo-price__inner--right {
  margin-right: 20px;
}

.cbo-price__list {
  display: block;
  margin: 0 auto;
  width: 385px;
  padding-left: 0px;
  padding-top: 60px;
  padding-bottom: 100px;
  min-height: 400px;
}

.cbo-price__list--left {
  padding-bottom: 115px;
}

.cbo-price__item {
  font-family: 'Helvetica';
  line-height: 35px;
  font-size: 1em;
  font-weight: 500;
  color: #424242;
}

.cbo-price__item--num {
  display: inline-block;
  width: 22px;
  margin-right: 18px;
  text-align: center;
  padding-top: 4px;
  height: 22px;
  background: #0BC1F8;
  border-radius: 5px;
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: #FFFFFF;
}

.cbo-price__item--numYellow {
  display: inline-block;
  width: 22px;
  margin-right: 18px;
  text-align: center;
  padding-top: 4px;
  height: 22px;
  background: #FFDE5B;
  border-radius: 5px;
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: #3E3E3E;
}

.cbo-price__priceWrp {
  margin: 0 auto;
  width: 385px;
  padding: 60px 0;
  border-top: 1px solid #CFCFCF;
}

.cbo-price__price {
  font-family: 'Helvetica';
  line-height: 47px;
  font-size: 1.125em;
  font-weight: 700;
  color: #3B3B3B;
}

.cbo-price__price--num {
  font-size: 2.625em;
  padding: 0 10px;
}

.cbo-price__price--hidden {
  display: none;
}

.cbo-price__tabWrp {
  margin: 0 auto;
  width: 200px;
  height: 50px;
  border: 1px solid #CFCFCF;
  border-radius: 15px 15px 0px 0px;
}

.cbo-price__tab {
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 1.125em;
  color: #8A8A8A;
  cursor: pointer;
  transition: all 0.4s ease;
}

.cbo-price__tab:hover {
  color: #FFF;
  background: #0BC1F8;
}

.cbo-price__tab--ip {
  display: inline-block;
  width: 97px;
  border-radius: 15px 0px 0px 0px;
  padding: 15px 30px;
}

.cbo-price__tab--too {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 0px 15px 0px 0px;
  width: 97px;
}

.cbo-price__tab--active {
  background: #0BC1F8;
  color: #FFF;
}

@media only screen and (max-width: 1440px) {
  .cbo-price__heading {
    font-size: 1.7em;
  }
  .cbo-price__item--num {
    padding-top: 6px;
  }
  .cbo-price__item--numYellow {
    padding-top: 6px;
  }
}

@media only screen and (max-width: 800px) {
  .cbo-price__inner--right {
    margin-right: 0;
  }
  .cbo-price__list {
    width: 280px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-price__list {
    width: 300px;
  }
  .cbo-price__priceWrp {
    width: 300px;
  }
}

@media only screen and (max-width: 360px) {
  .cbo-price__inner--right {
    margin-right: 0;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-price__list {
    width: 265px;
  }
}

.cbo-reviews {
  background: #FFDE5B;
  padding-top: 123px;
  padding-bottom: 100px;
}

.cbo-reviews__item {
  max-width: 1050px;
  margin: 0 auto;
}

.cbo-reviews__reviewWrp {
  padding: 80px 0 80px 80px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  box-shadow: 0px 4px 75px rgba(85, 85, 85, 0.15);
  border-radius: 10px;
  margin-bottom: 40px;
}

.cbo-reviews__imgWrp {
  width: 170px;
  height: 170px;
  display: inline-block;
}

.cbo-reviews__txtWrp {
  display: inline-block;
  max-width: 630px;
  margin-left: 90px;
}

.cbo-reviews__txtWrp--right {
  max-width: 610px;
  margin-left: 0;
  margin-right: 90px;
}

.cbo-reviews__name {
  font-family: 'Helvetica';
  line-height: 28px;
  font-size: 1.25em;
  font-weight: 700;
  color: #2D2D2D;
  display: block;
}

.cbo-reviews__company {
  color: #05BDF7;
  font-family: 'Helvetica';
  line-height: 28px;
  font-size: 1em;
  font-weight: 400;
}

.cbo-reviews__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  font-weight: 400;
  color: #6A6A6A;
}

.cbo-reviews .owl-carousel .owl-nav {
  position: relative;
  transform: translateY(-50vh);
}

.cbo-reviews .owl-carousel .owl-nav .owl-prev {
  display: inline-block;
  position: absolute;
  left: 100px;
  background: url("../images/cbo-reviews-arrow.png") no-repeat;
  width: 35px;
  height: 30px;
}

.cbo-reviews .owl-carousel .owl-nav .owl-next {
  display: inline-block;
  position: absolute;
  right: 100px;
  background: url("../images/cbo-reviews-arrow.png") no-repeat;
  width: 35px;
  height: 30px;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1440px) {
  .cbo-reviews__reviewWrp {
    padding: 40px 0 40px 80px;
  }
  .cbo-reviews__name {
    font-size: 1.5em;
  }
  .cbo-reviews__company {
    font-size: 1.2em;
  }
  .cbo-reviews__text {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 1366px) {
  .cbo-reviews .owl-carousel .owl-nav {
    position: relative;
    transform: translateY(-49vh);
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-prev {
    display: inline-block;
    position: absolute;
    left: 50px;
    background: url("../images/cbo-reviews-arrow.png") no-repeat;
    width: 35px;
    height: 30px;
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-next {
    display: inline-block;
    position: absolute;
    right: 50px;
    background: url("../images/cbo-reviews-arrow.png") no-repeat;
    width: 35px;
    height: 30px;
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 800px) {
  .cbo-reviews .owl-carousel .owl-nav {
    position: relative;
    transform: translateY(-58vh);
  }
  .cbo-reviews__item {
    max-width: 700px;
  }
  .cbo-reviews__reviewWrp {
    padding: 40px 0 40px 40px;
  }
  .cbo-reviews__txtWrp {
    max-width: 430px;
    margin-left: 20px;
  }
  .cbo-reviews__txtWrp--right {
    margin-right: 20px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-73vh);
  }
}

@media only screen and (max-width: 600px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-80vh);
  }
  .cbo-reviews__item {
    max-width: 560px;
  }
  .cbo-reviews__reviewWrp {
    padding: 20px;
  }
  .cbo-reviews__txtWrp {
    max-width: 320px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-reviews__item {
    max-width: 380px;
  }
  .cbo-reviews__reviewWrp {
    padding: 20px 0;
  }
  .cbo-reviews__txtWrp {
    margin-left: 0;
  }
  .cbo-reviews__txtWrp--right {
    margin-right: 0;
  }
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-85vh);
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-next {
    right: 20px;
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-prev {
    left: 20px;
  }
}

@media only screen and (max-width: 384px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-97vh);
  }
}

@media only screen and (max-width: 375px) {
  .cbo-reviews__item {
    max-width: 350px;
  }
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-93vh);
  }
}

@media only screen and (max-width: 360px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-97vh);
  }
}

@media only screen and (max-width: 320px) {
  .cbo-reviews__reviewWrp {
    padding: 20px;
  }
  .cbo-reviews__item {
    max-width: 290px;
  }
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-118vh);
  }
}

.cbo-services {
  background: #EEFAFE;
  padding-bottom: 60px;
}

.cbo-services__title {
  margin-top: 120px;
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 700;
  font-size: 2.375em;
  color: #3E3E3E;
  padding-bottom: 85px;
}

.cbo-services__title--price {
  padding-bottom: 40px;
  margin: 0;
}

.cbo-services__title--reviews {
  margin: 0;
  padding-bottom: 85px;
}

.cbo-services__subtitle {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1.375em;
  font-weight: 700;
  color: #3E3E3E;
}

.cbo-services__txtWrp {
  max-width: 415px;
}

.cbo-services__img {
  max-height: 240px;
}

.cbo-services__imgWrp {
  height: 300px;
  max-width: 400px;
}

.cbo-services__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  color: #7A7A7A;
}

@media only screen and (max-width: 800px) {
  .cbo-services__txtWrp {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-services__imgWrp {
    max-width: none;
  }
}

.cbo-steps {
  background: #05BDF7;
  padding-top: 80px;
  padding-bottom: 100px;
}

.cbo-steps__title {
  font-family: 'Helvetica';
  font-size: 2.375em;
  color: #FFF;
  font-weight: 700;
  margin-bottom: 30px;
}

.cbo-steps__subtitle {
  font-family: 'Helvetica';
  font-size: 1.125em;
  color: #FFF;
  font-weight: 400;
}

.cbo-steps__steps {
  display: inline-block;
  background: #FFF;
  width: 340px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 60px 0 40px 60px;
  text-align: left;
  position: relative;
  margin-right: 20px;
  margin-top: 60px;
}

.cbo-steps__steps:last-child {
  margin-right: 0;
}

.cbo-steps__numWrp {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #FFE100;
  border-radius: 50%;
  text-align: center;
  padding-top: 20px;
  left: -15%;
  top: 35%;
}

.cbo-steps__num {
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 700;
  font-size: 2.1875em;
  color: #FFFFFF;
}

.cbo-steps__stepSubtitle {
  font-family: 'Helvetica';
  line-height: 33px;
  font-size: 1.1875em;
  color: #00C1FF;
  font-weight: 700;
}

.cbo-steps__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  color: #3E3E3E;
  max-width: 235px;
}

@media only screen and (max-width: 1440px) {
  .cbo-steps__text {
    font-size: 1.2em;
  }
  .cbo-steps__stepSubtitle {
    font-size: 1.5em;
  }
  .cbo-steps__numWrp {
    padding-top: 24px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-steps__numWrp {
    width: 60px;
    height: 60px;
    left: -10%;
    padding-top: 16px;
  }
  .cbo-steps__steps {
    width: 100%;
    padding: 20px 0 20px 45px;
  }
}

@media only screen and (max-width: 375px) {
  .cbo-steps__steps {
    width: 310px;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-steps__steps {
    width: 260px;
  }
}

.cbo-whyme__iconWrp {
  background: #FFDE5B;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  text-align: center;
  padding-top: 20px;
  display: inline-block;
}

.cbo-whyme__txtWrp {
  display: inline-block;
  max-width: 400px;
  margin-bottom: 118px;
  padding-left: 35px;
  text-align: left;
}

.cbo-whyme__subtitle {
  font-family: "Helvetica";
  line-height: 25px;
  font-weight: 500;
  font-size: 1.25em;
}

.cbo-whyme__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  color: #7A7A7A;
}

@media only screen and (max-width: 414px) {
  .cbo-whyme__txtWrp {
    text-align: center;
    margin-bottom: 80px;
    padding-left: 0;
  }
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.cbo-advs {
  background: rgba(255, 222, 91, 0.14);
  padding-top: 80px;
  padding-bottom: 140px;
}

.cbo-advs__title {
  font-weight: 400;
  font-family: 'Helvetica';
  line-height: normal;
  font-size: 1.625em;
  color: #3E3E3E;
  margin-bottom: 114px;
}

.cbo-advs__imgWrp {
  min-height: 70px;
}

.cbo-advs__text {
  font-family: 'Helvetica';
  line-height: 23px;
  font-weight: 500;
  font-size: 1em;
  color: #3E3E3E;
}

.cbo-footer {
  background: #525252;
  padding-top: 90px;
  padding-bottom: 35px;
}

.cbo-footer__logoTxt {
  font-family: 'Helvetica';
  line-height: 20px;
  font-size: 1em;
  color: #FFFFFF;
  font-weight: 700;
  display: inline-block;
  transform: translateY(-5px);
}

.cbo-footer__subtitle {
  font-family: 'Helvetica';
  line-height: 23px;
  font-size: 1em;
  font-weight: 500;
  color: #8B8B8B;
  margin-bottom: 0;
}

.cbo-footer__inner {
  display: inline-block;
  max-width: 240px;
  margin-top: 10px;
  margin-left: 70px;
}

.cbo-footer__list {
  display: inline-block;
  padding-left: 70px;
  margin: 0;
}

.cbo-footer__link {
  line-height: 40px;
  text-decoration: none;
  font-family: 'Helvetica';
  font-size: 1em;
  color: #FFFFFF;
  font-weight: 400;
  transition: color 0.4s ease;
}

.cbo-footer__link:hover {
  color: #00C1FF;
}

.cbo-footer__contacts {
  font-weight: 400;
  font-family: 'Helvetica';
  line-height: 22px;
  font-size: 1em;
  color: #FFFFFF;
  margin-bottom: 25px;
  display: block;
}

.cbo-footer__mapWrp {
  display: inline-block;
  width: 330px;
  height: 240px;
}

.cbo-footer__left {
  padding-top: 10px;
}

.cbo-footer__copyrightWrp {
  padding-top: 50px;
}

.cbo-footer__copyright {
  font-family: 'Helvetica';
  line-height: 23px;
  font-size: 1em;
  font-weight: 400;
  display: block;
  color: #8B8B8B;
}

@media only screen and (max-width: 800px) {
  .cbo-footer__mapWrp {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .cbo-footer__inner {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-footer__inner {
    margin-left: 40px;
    max-width: 190px;
  }
  .cbo-footer__list {
    padding-left: 45px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-footer__inner {
    margin-left: 15px;
    max-width: 174px;
  }
}

@media only screen and (max-width: 384px) {
  .cbo-footer__inner {
    margin-left: 0;
  }
}

@media only screen and (max-width: 375px) {
  .cbo-footer__inner {
    max-width: 170px;
  }
}

@media only screen and (max-width: 360px) {
  .cbo-footer__inner {
    max-width: 163px;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-footer__inner {
    max-width: 100%;
    text-align: center;
  }
}

.cbo-main-nav {
  padding: 15px 0;
  background: #FFF;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 103px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
}

.cbo-main-nav__logoTxt {
  padding-left: 5px;
  transform: translateY(-10px);
  display: inline-block;
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 1em;
  color: #393939;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}

.cbo-main-nav__logo {
  width: 33px;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}

.cbo-main-nav__list {
  padding-left: 0;
  display: inline-block;
  margin-top: 30px;
}

.cbo-main-nav__item {
  display: inline-block;
  padding-right: 40px;
}

.cbo-main-nav__telWrp {
  margin-left: 25px;
  display: inline-block;
}

.cbo-main-nav__telWrp--burger {
  margin-left: 40px;
}

.cbo-main-nav__tel {
  font-family: 'Helvetica';
  font-size: 1em;
  font-weight: 700;
  color: #3E3E3E;
}

.cbo-main-nav__link {
  text-decoration: none;
  font-family: 'Helvetica';
  font-weight: 400;
  font-size: 1em;
  color: #2D2D2D;
  transition: color 0.4s ease;
}

.cbo-main-nav__link:hover {
  color: #00C1FF;
}

.cbo-main-nav__btnWrp {
  margin-top: 25px;
}

.cbo-main-nav__btnWrp--price {
  margin-top: 0;
}

.cbo-main-nav__btnWrp--burger {
  display: inline-block;
  margin-left: 40px;
}

.cbo-main-nav__button {
  box-shadow: 0px 3px 0px rgba(50, 50, 50, 0.3);
  padding: 10px 25px;
  background: #FFE100;
  border-radius: 5px;
  font-family: 'Helvetica';
  font-weight: 500;
  font-size: 1em;
  color: #2D2D2D;
  text-decoration: none;
  cursor: pointer;
}

.cbo-main-nav__button--price {
  padding: 15px 80px;
}

.cbo-main-nav__button--form {
  width: 100%;
  padding: 22px 80px;
}

.cbo-main-nav__logoWrp {
  display: inline-block;
}

.cbo-main-nav__burgerWrp {
  display: inline-block;
  padding-top: 10px;
  padding-right: 50px;
}

.cbo-main-nav__burger {
  margin: 0 auto;
  width: 40px;
  height: 40px;
}

.cbo-main-nav__burgerItem {
  display: block;
  width: 38px;
  height: 3px;
  background-color: #3D3D3D;
  border-radius: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 1440px) {
  .cbo-main-nav__inner {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .cbo-main-nav__list {
    display: flex;
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: stretch;
    z-index: 9;
    background-color: #FFF;
  }
  .cbo-main-nav__item {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
    padding-right: 0;
    display: flex;
    text-align: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    padding-top: 90px;
  }
  .cbo-main-nav__item:last-child {
    border-bottom: none;
  }
  .cbo-main-nav__link {
    font-size: 2.5em;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 768px) {
  .cbo-main-nav__btnWrp--burger {
    margin-left: 34px;
  }
  .cbo-main-nav__item {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-main-nav__btnWrp--burger {
    display: none;
  }
  .cbo-main-nav__telWrp {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-main-nav__item {
    padding-top: 50px;
  }
  .cbo-main-nav__link {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-main-nav__button--form {
    padding: 10px 75px;
  }
}

.cbo-main {
  background: url("../images/cbo-main.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 675px;
  padding-top: 60px;
  margin-top: 100px;
}

.cbo-main__left {
  margin-top: 65px;
}

.cbo-main__title {
  line-height: 59px;
  margin: 0;
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 2.875em;
  color: #FFFFFF;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding-bottom: 40px;
}

.cbo-main__subtitle {
  font-family: 'Helvetica';
  font-size: 1.4375em;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
}

.cbo-main__watchWrp {
  margin-top: 150px;
}

.cbo-main__watch {
  font-family: 'Helvetica';
  font-size: 1.25em;
  font-weight: 700;
  color: #FFFFFF;
}

.cbo-main__arrow {
  display: inline-block;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 1px solid #FFF;
  margin-left: 30px;
  transform: translateY(-5px);
  color: #FFF;
  text-align: center;
  padding-top: 10px;
  font-size: 1.125em;
  text-decoration: none;
}

.cbo-main__steps {
  background: #FFF;
  padding: 40px 28px 40px 73px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 24px;
  width: 500px;
  height: 170px;
  position: relative;
}

.cbo-main__steps:last-child {
  margin-bottom: 0;
}

.cbo-main__steps--second {
  padding: 30px 28px 40px 73px;
}

.cbo-main__stepsSubtitle {
  font-family: 'Helvetica';
  font-size: 1.25em;
  color: #00C1FF;
  font-weight: 700;
}

.cbo-main__text {
  font-family: 'Helvetica';
  font-size: 1em;
  font-weight: 400;
  line-height: 25px;
  color: #3E3E3E;
}

.cbo-main__textInner {
  text-decoration: underline;
  font-weight: 700;
}

.cbo-main__textInnerLink {
  color: #3E3E3E;
}

.cbo-main__numWrp {
  text-align: center;
  position: absolute;
  left: -8%;
  top: 25%;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #FFE100;
  padding-top: 20px;
}

.cbo-main__num {
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 700;
  font-size: 2.1875em;
  color: #FFFFFF;
}

@media only screen and (max-width: 1440px) {
  .cbo-main__numWrp {
    padding-top: 24px;
  }
  .cbo-main__arrow {
    padding-top: 13px;
  }
}

@media only screen and (max-width: 1366px) {
  .cbo-main__stepsSubtitle {
    font-size: 1.5em;
  }
  .cbo-main__text {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 800px) {
  .cbo-main__left {
    margin-left: 40px;
  }
  .cbo-main__steps {
    width: 370px;
    padding: 30px 28px 40px 73px;
  }
  .cbo-main__numWrp {
    padding-top: 26px;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-main__watchWrp {
    margin-top: 90px;
  }
  .cbo-main__steps {
    width: 260px;
    padding: 30px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-main {
    height: 1000px;
    padding-top: 20px;
  }
  .cbo-main__left {
    margin-top: 0;
    margin-left: 25px;
  }
  .cbo-main__steps {
    width: 100%;
    padding: 24px;
  }
  .cbo-main__right {
    margin-top: 50px;
  }
}

.cbo-price {
  padding-top: 100px;
  padding-bottom: 140px;
}

.cbo-price__subtitle {
  font-family: 'Helvetica';
  font-weight: 400;
  font-size: 1.125em;
  color: #919191;
  padding-bottom: 90px;
}

.cbo-price__headingWrp {
  background: #0BC1F8;
  border-radius: 10px 10px 0px 0px;
  padding: 40px 60px;
}

.cbo-price__headingWrp--right {
  background: #FFDE5B;
}

.cbo-price__heading {
  font-family: 'Helvetica';
  font-weight: 700;
  line-height: 35px;
  font-size: 1.5em;
  color: #FFFFFF;
}

.cbo-price__heading--right {
  color: #3E3E3E;
}

.cbo-price__inner {
  background: #F7F7F7;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 100px;
  margin-top: 90px;
}

.cbo-price__inner--right {
  margin-right: 20px;
}

.cbo-price__list {
  display: block;
  margin: 0 auto;
  width: 385px;
  padding-left: 0px;
  padding-top: 60px;
  padding-bottom: 100px;
  min-height: 400px;
}

.cbo-price__list--left {
  padding-bottom: 115px;
}

.cbo-price__item {
  font-family: 'Helvetica';
  line-height: 35px;
  font-size: 1em;
  font-weight: 500;
  color: #424242;
}

.cbo-price__item--num {
  display: inline-block;
  width: 22px;
  margin-right: 18px;
  text-align: center;
  padding-top: 4px;
  height: 22px;
  background: #0BC1F8;
  border-radius: 5px;
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: #FFFFFF;
}

.cbo-price__item--numYellow {
  display: inline-block;
  width: 22px;
  margin-right: 18px;
  text-align: center;
  padding-top: 4px;
  height: 22px;
  background: #FFDE5B;
  border-radius: 5px;
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: #3E3E3E;
}

.cbo-price__priceWrp {
  margin: 0 auto;
  width: 385px;
  padding: 60px 0;
  border-top: 1px solid #CFCFCF;
}

.cbo-price__price {
  font-family: 'Helvetica';
  line-height: 47px;
  font-size: 1.125em;
  font-weight: 700;
  color: #3B3B3B;
}

.cbo-price__price--num {
  font-size: 2.625em;
  padding: 0 10px;
}

.cbo-price__price--hidden {
  display: none;
}

.cbo-price__tabWrp {
  margin: 0 auto;
  width: 200px;
  height: 50px;
  border: 1px solid #CFCFCF;
  border-radius: 15px 15px 0px 0px;
}

.cbo-price__tab {
  font-family: 'Helvetica';
  font-weight: 700;
  font-size: 1.125em;
  color: #8A8A8A;
  cursor: pointer;
  transition: all 0.4s ease;
}

.cbo-price__tab:hover {
  color: #FFF;
  background: #0BC1F8;
}

.cbo-price__tab--ip {
  display: inline-block;
  width: 97px;
  border-radius: 15px 0px 0px 0px;
  padding: 15px 30px;
}

.cbo-price__tab--too {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 0px 15px 0px 0px;
  width: 97px;
}

.cbo-price__tab--active {
  background: #0BC1F8;
  color: #FFF;
}

@media only screen and (max-width: 1440px) {
  .cbo-price__heading {
    font-size: 1.7em;
  }
  .cbo-price__item--num {
    padding-top: 6px;
  }
  .cbo-price__item--numYellow {
    padding-top: 6px;
  }
}

@media only screen and (max-width: 800px) {
  .cbo-price__inner--right {
    margin-right: 0;
  }
  .cbo-price__list {
    width: 280px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-price__list {
    width: 300px;
  }
  .cbo-price__priceWrp {
    width: 300px;
  }
}

@media only screen and (max-width: 360px) {
  .cbo-price__inner--right {
    margin-right: 0;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-price__list {
    width: 265px;
  }
}

.cbo-reviews {
  background: #FFDE5B;
  padding-top: 123px;
  padding-bottom: 100px;
}

.cbo-reviews__item {
  max-width: 1050px;
  margin: 0 auto;
}

.cbo-reviews__reviewWrp {
  padding: 80px 0 80px 80px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  box-shadow: 0px 4px 75px rgba(85, 85, 85, 0.15);
  border-radius: 10px;
  margin-bottom: 40px;
}

.cbo-reviews__imgWrp {
  width: 170px;
  height: 170px;
  display: inline-block;
}

.cbo-reviews__txtWrp {
  display: inline-block;
  max-width: 630px;
  margin-left: 90px;
}

.cbo-reviews__txtWrp--right {
  max-width: 610px;
  margin-left: 0;
  margin-right: 90px;
}

.cbo-reviews__name {
  font-family: 'Helvetica';
  line-height: 28px;
  font-size: 1.25em;
  font-weight: 700;
  color: #2D2D2D;
  display: block;
}

.cbo-reviews__company {
  color: #05BDF7;
  font-family: 'Helvetica';
  line-height: 28px;
  font-size: 1em;
  font-weight: 400;
}

.cbo-reviews__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  font-weight: 400;
  color: #6A6A6A;
}

.cbo-reviews .owl-carousel .owl-nav {
  position: relative;
  transform: translateY(-50vh);
}

.cbo-reviews .owl-carousel .owl-nav .owl-prev {
  display: inline-block;
  position: absolute;
  left: 100px;
  background: url("../images/cbo-reviews-arrow.png") no-repeat;
  width: 35px;
  height: 30px;
}

.cbo-reviews .owl-carousel .owl-nav .owl-next {
  display: inline-block;
  position: absolute;
  right: 100px;
  background: url("../images/cbo-reviews-arrow.png") no-repeat;
  width: 35px;
  height: 30px;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1440px) {
  .cbo-reviews__reviewWrp {
    padding: 40px 0 40px 80px;
  }
  .cbo-reviews__name {
    font-size: 1.5em;
  }
  .cbo-reviews__company {
    font-size: 1.2em;
  }
  .cbo-reviews__text {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 1366px) {
  .cbo-reviews .owl-carousel .owl-nav {
    position: relative;
    transform: translateY(-49vh);
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-prev {
    display: inline-block;
    position: absolute;
    left: 50px;
    background: url("../images/cbo-reviews-arrow.png") no-repeat;
    width: 35px;
    height: 30px;
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-next {
    display: inline-block;
    position: absolute;
    right: 50px;
    background: url("../images/cbo-reviews-arrow.png") no-repeat;
    width: 35px;
    height: 30px;
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 800px) {
  .cbo-reviews .owl-carousel .owl-nav {
    position: relative;
    transform: translateY(-58vh);
  }
  .cbo-reviews__item {
    max-width: 700px;
  }
  .cbo-reviews__reviewWrp {
    padding: 40px 0 40px 40px;
  }
  .cbo-reviews__txtWrp {
    max-width: 430px;
    margin-left: 20px;
  }
  .cbo-reviews__txtWrp--right {
    margin-right: 20px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-73vh);
  }
}

@media only screen and (max-width: 600px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-80vh);
  }
  .cbo-reviews__item {
    max-width: 560px;
  }
  .cbo-reviews__reviewWrp {
    padding: 20px;
  }
  .cbo-reviews__txtWrp {
    max-width: 320px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-reviews__item {
    max-width: 380px;
  }
  .cbo-reviews__reviewWrp {
    padding: 20px 0;
  }
  .cbo-reviews__txtWrp {
    margin-left: 0;
  }
  .cbo-reviews__txtWrp--right {
    margin-right: 0;
  }
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-85vh);
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-next {
    right: 20px;
  }
  .cbo-reviews .owl-carousel .owl-nav .owl-prev {
    left: 20px;
  }
}

@media only screen and (max-width: 384px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-97vh);
  }
}

@media only screen and (max-width: 375px) {
  .cbo-reviews__item {
    max-width: 350px;
  }
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-93vh);
  }
}

@media only screen and (max-width: 360px) {
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-97vh);
  }
}

@media only screen and (max-width: 320px) {
  .cbo-reviews__reviewWrp {
    padding: 20px;
  }
  .cbo-reviews__item {
    max-width: 290px;
  }
  .cbo-reviews .owl-carousel .owl-nav {
    transform: translateY(-118vh);
  }
}

.cbo-services {
  background: #EEFAFE;
  padding-bottom: 60px;
}

.cbo-services__title {
  margin-top: 120px;
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 700;
  font-size: 2.375em;
  color: #3E3E3E;
  padding-bottom: 85px;
}

.cbo-services__title--price {
  padding-bottom: 40px;
  margin: 0;
}

.cbo-services__title--reviews {
  margin: 0;
  padding-bottom: 85px;
}

.cbo-services__subtitle {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1.375em;
  font-weight: 700;
  color: #3E3E3E;
}

.cbo-services__txtWrp {
  max-width: 415px;
}

.cbo-services__img {
  max-height: 240px;
}

.cbo-services__imgWrp {
  height: 300px;
  max-width: 400px;
}

.cbo-services__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  color: #7A7A7A;
}

@media only screen and (max-width: 800px) {
  .cbo-services__txtWrp {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .cbo-services__imgWrp {
    max-width: none;
  }
}

.cbo-steps {
  background: #05BDF7;
  padding-top: 80px;
  padding-bottom: 100px;
}

.cbo-steps__title {
  font-family: 'Helvetica';
  font-size: 2.375em;
  color: #FFF;
  font-weight: 700;
  margin-bottom: 30px;
}

.cbo-steps__subtitle {
  font-family: 'Helvetica';
  font-size: 1.125em;
  color: #FFF;
  font-weight: 400;
}

.cbo-steps__steps {
  display: inline-block;
  background: #FFF;
  width: 340px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 60px 0 40px 60px;
  text-align: left;
  position: relative;
  margin-right: 20px;
  margin-top: 60px;
}

.cbo-steps__steps:last-child {
  margin-right: 0;
}

.cbo-steps__numWrp {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #FFE100;
  border-radius: 50%;
  text-align: center;
  padding-top: 20px;
  left: -15%;
  top: 35%;
}

.cbo-steps__num {
  font-family: 'Helvetica';
  line-height: normal;
  font-weight: 700;
  font-size: 2.1875em;
  color: #FFFFFF;
}

.cbo-steps__stepSubtitle {
  font-family: 'Helvetica';
  line-height: 33px;
  font-size: 1.1875em;
  color: #00C1FF;
  font-weight: 700;
}

.cbo-steps__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  color: #3E3E3E;
  max-width: 235px;
}

@media only screen and (max-width: 1440px) {
  .cbo-steps__text {
    font-size: 1.2em;
  }
  .cbo-steps__stepSubtitle {
    font-size: 1.5em;
  }
  .cbo-steps__numWrp {
    padding-top: 24px;
  }
}

@media only screen and (max-width: 414px) {
  .cbo-steps__numWrp {
    width: 60px;
    height: 60px;
    left: -10%;
    padding-top: 16px;
  }
  .cbo-steps__steps {
    width: 100%;
    padding: 20px 0 20px 45px;
  }
}

@media only screen and (max-width: 375px) {
  .cbo-steps__steps {
    width: 310px;
  }
}

@media only screen and (max-width: 320px) {
  .cbo-steps__steps {
    width: 260px;
  }
}

.cbo-whyme__iconWrp {
  background: #FFDE5B;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  text-align: center;
  padding-top: 20px;
  display: inline-block;
}

.cbo-whyme__txtWrp {
  display: inline-block;
  max-width: 400px;
  margin-bottom: 118px;
  padding-left: 35px;
  text-align: left;
}

.cbo-whyme__subtitle {
  font-family: "Helvetica";
  line-height: 25px;
  font-weight: 500;
  font-size: 1.25em;
}

.cbo-whyme__text {
  font-family: 'Helvetica';
  line-height: 25px;
  font-size: 1em;
  color: #7A7A7A;
}

@media only screen and (max-width: 414px) {
  .cbo-whyme__txtWrp {
    text-align: center;
    margin-bottom: 80px;
    padding-left: 0;
  }
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}
