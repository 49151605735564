.cbo-advs {
	background: rgba(255, 222, 91, 0.14);
	padding-top: 80px;
	padding-bottom: 140px;
	&__title {
		font-weight: 400;
		font-family: 'Helvetica';
		line-height: normal;
		font-size: em(26);
		color: #3E3E3E;
		margin-bottom: 114px;
	}
	&__imgWrp {
		min-height: 70px;
	}
	&__text {
		font-family: 'Helvetica';
		line-height: 23px;
		font-weight: 500;
		font-size: em(16);
		color: #3E3E3E;
	}
}