.cbo-reviews {
	background: #FFDE5B;
	padding-top: 123px;
	padding-bottom: 100px;
	&__item {
		max-width: 1050px;
		margin: 0 auto;
	}
	&__reviewWrp {
		padding: 80px 0 80px 80px;
		background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
		box-shadow: 0px 4px 75px rgba(85, 85, 85, 0.15);
		border-radius: 10px;
		margin-bottom: 40px;	
	}
	&__imgWrp {
		width: 170px;
		height: 170px;
		display: inline-block;
	}
	&__txtWrp {
		display: inline-block;
		max-width: 630px;
		margin-left: 90px;
		&--right {
			max-width: 610px;
			margin-left: 0;
			margin-right: 90px;
		}
	}
	&__name {
		font-family: 'Helvetica';
		line-height: 28px;
		font-size: em(20);
		font-weight: 700;
		color: #2D2D2D;
		display: block;
	}
	&__company {
		color: #05BDF7;
		font-family: 'Helvetica';
		line-height: 28px;
		font-size: em(16);
		font-weight: 400;
	}
	&__text {	
		font-family: 'Helvetica';
		line-height: 25px;
		font-size: em(16);
		font-weight: 400;
		color: #6A6A6A;
	}
	.owl-carousel {
		.owl-nav {
			position: relative;
			transform: translateY(-50vh);
			.owl-prev {
				display: inline-block;
				position: absolute;
				left: 100px;
				background: url("../images/cbo-reviews-arrow.png") no-repeat;
				width: 35px;
				height: 30px;
			}
			.owl-next {
				display: inline-block;
				position: absolute;
				right: 100px;
				background: url("../images/cbo-reviews-arrow.png") no-repeat;
				width: 35px;
				height: 30px;
				transform: rotate(180deg);
			}
		}
	}
}

@media only screen and (max-width: 1440px) {
	.cbo-reviews {
		&__reviewWrp {
			padding: 40px 0 40px 80px;
		}
		&__name {
			font-size: 1.5em;
		}
		&__company {
			font-size: 1.2em;
		}
		&__text {
			font-size: 1.2em;
		}
	}
}

@media only screen and (max-width: 1366px) {
	.cbo-reviews {
		.owl-carousel {
			.owl-nav {
			position: relative;
			transform: translateY(-49vh);
				.owl-prev {
					display: inline-block;
					position: absolute;
					left: 50px;
					background: url("../images/cbo-reviews-arrow.png") no-repeat;
					width: 35px;
					height: 30px;
				}
				.owl-next {
					display: inline-block;
					position: absolute;
					right: 50px;
					background: url("../images/cbo-reviews-arrow.png") no-repeat;
					width: 35px;
					height: 30px;
					transform: rotate(180deg);
				}
			}
		}
	}
}
@media only screen and (max-width: 800px) {
	.cbo-reviews {
		.owl-carousel {
			.owl-nav {
				position: relative;
				transform: translateY(-58vh);
			}
		}
		&__item {
			max-width: 700px;
		}
		&__reviewWrp {
		    padding: 40px 0 40px 40px;
		}
		&__txtWrp {
			max-width: 430px;
			margin-left: 20px;
			&--right {
				margin-right: 20px;
				margin-left: 0;
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	.cbo-reviews {
		.owl-carousel {
			.owl-nav {
				transform: translateY(-73vh);
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.cbo-reviews {
		.owl-carousel {
			.owl-nav {
				transform: translateY(-80vh);
			}
		}
		&__item {
			max-width: 560px;
		}
		&__reviewWrp {
			padding: 20px;
		}
		&__txtWrp {
			max-width: 320px;
		}
	}
}
@media only screen and (max-width: 414px) {
	.cbo-reviews {
		&__item {
			max-width: 380px;
		}
		&__reviewWrp {
			padding: 20px 0;
		}
		&__txtWrp {
			margin-left: 0;
			&--right {
				margin-right: 0;
			}
		}
		.owl-carousel {
			.owl-nav {
				transform: translateY(-85vh);
				.owl-next {
					right: 20px;
				}
				.owl-prev {
					left: 20px;
				}
			}
		}
	}
}
@media only screen and (max-width: 384px) {
	.cbo-reviews {
		.owl-carousel {
			.owl-nav {
				transform: translateY(-97vh);
			}
		}
	}
}
@media only screen and (max-width: 375px) {
	.cbo-reviews {
		&__item {
			max-width: 350px;
		}
		.owl-carousel {
			.owl-nav {
				transform: translateY(-93vh);
			}
		}
	}
}
@media only screen and (max-width: 360px) {
	.cbo-reviews {
		.owl-carousel {
			.owl-nav {
				transform: translateY(-97vh);
			}
		}
	}
}
@media only screen and (max-width: 320px) {
	.cbo-reviews {
		&__reviewWrp {
			padding: 20px;
		}
		&__item {
			max-width: 290px;
		}
		.owl-carousel {
			.owl-nav {
				transform: translateY(-118vh);
			}
		}
	}
}