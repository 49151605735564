.cbo-price {
	padding-top: 100px;
	padding-bottom: 140px;
	&__subtitle {
		font-family: 'Helvetica';
		font-weight: 400;
		font-size: em(18);
		color: #919191;
		padding-bottom: 90px;
	}
	&__headingWrp {
		background: #0BC1F8;
		border-radius: 10px 10px 0px 0px;
		padding: 40px 60px;
		&--right {
			background: #FFDE5B;
		}
	}
	&__heading {
		font-family: 'Helvetica';
		font-weight: 700;
		line-height: 35px;
		font-size: em(24);
		color: #FFFFFF;
		&--right {
			color: #3E3E3E;
		}
	}
	&__inner{
		background: #F7F7F7;
		border-radius: 0px 0px 10px 10px;
		padding-bottom: 100px;
		margin-top: 90px;
		&--right {
			margin-right: 20px;
		}
	}
	&__list {
		display: block;
		margin: 0 auto;
		width: 385px;
		padding-left: 0px;
		padding-top: 60px;
		padding-bottom: 100px;
		min-height: 400px;
		&--left {
			padding-bottom: 115px;
		}
	}
	&__item {
		font-family: 'Helvetica';
		line-height: 35px;
		font-size: em(16);
		font-weight: 500;
		color: #424242;
		&--num {
			display: inline-block;
			width: 22px;
			margin-right: 18px;
			text-align: center;
			padding-top: 4px;
			height: 22px;
			background: #0BC1F8;
			border-radius: 5px;
			font-family: 'Helvetica';
			line-height: normal;
			font-weight: 500;
			font-size: em(12);
			color: #FFFFFF;
		}
		&--numYellow {
			display: inline-block;
			width: 22px;
			margin-right: 18px;
			text-align: center;
			padding-top: 4px;
			height: 22px;
			background: #FFDE5B;
			border-radius: 5px;
			font-family: 'Helvetica';
			line-height: normal;
			font-weight: 500;
			font-size: em(12);
			color: #3E3E3E;
		}
	}
	&__priceWrp {
		margin: 0 auto;
		width: 385px;
		padding: 60px 0;
		border-top: 1px solid #CFCFCF;
	}
	&__price {
		font-family: 'Helvetica';
		line-height: 47px;
		font-size: em(18);
		font-weight: 700;
		color: #3B3B3B; 
		&--num {
			font-size: em(42);
			padding: 0 10px;
		}
		&--hidden {
			display: none;
		}
	}
	&__tabWrp {
		margin: 0 auto;
		width: 200px;
		height: 50px;
		border: 1px solid #CFCFCF;
		border-radius: 15px 15px 0px 0px;
	}	
	&__tab {
		font-family: 'Helvetica';
		font-weight: 700;
		font-size: em(18);
		color: #8A8A8A;
		cursor: pointer;
		transition: all 0.4s ease;
		&:hover {
			color: #FFF;
			background: #0BC1F8;
		}
		&--ip {
			display: inline-block;
			width: 97px;	
			border-radius: 15px 0px 0px 0px;
			padding: 15px 30px;
		}
		&--too {
			display: inline-block;
			padding: 15px 30px;
			border-radius: 0px 15px 0px 0px;
			width: 97px;
		}
		&--active {
			background: #0BC1F8;
			color: #FFF;
		}
	}
}

@media only screen and (max-width: 1440px) {
	.cbo-price {
		&__heading {
			font-size: 1.7em;
		}
		&__item {
			&--num {
				padding-top: 6px;
			}
			&--numYellow {
				padding-top: 6px;
			}
		}
	}
}
@media only screen and (max-width: 800px) {
	.cbo-price {
		&__inner {
			&--right {
				margin-right: 0;
			}
		}
		&__list {
			width: 280px;
		}
	}
}

@media only screen and (max-width: 414px) {
	.cbo-price {
		&__list {
			width: 300px;
		}
		&__priceWrp {
			width: 300px;
		}
	}
}
@media only screen and (max-width: 360px) {
	.cbo-price {
		&__inner {
			&--right {
				margin-right: 0;
			}
		}
	}
}
@media only screen and (max-width: 320px) {
	.cbo-price {
		&__list {
			width: 265px; 
		}
	}
}