.cbo-services {
	background: #EEFAFE;
	padding-bottom: 60px;
	&__title {
		margin-top: 120px;
		font-family: 'Helvetica';
		line-height: normal;
		font-weight: 700;
		font-size: em(38);
		color: #3E3E3E;
		padding-bottom: 85px;
		&--price {
			padding-bottom: 40px;
			margin: 0;
		}
		&--reviews {
			margin: 0;
			padding-bottom: 85px;
		}
	}
	&__subtitle {
		font-family: 'Helvetica';
		line-height: 25px;
		font-size: em(22);
		font-weight: 700;
		color: #3E3E3E;
	}
	&__txtWrp {
		max-width: 415px;
	}
	&__img {
		max-height: 240px;
	}
	&__imgWrp {
		height: 300px;
		max-width: 400px;
		&--padding {
			// padding-right: 50px;
		}
	}
	&__text {
		font-family: 'Helvetica';
		line-height: 25px;
		font-size: em(16);
		color: #7A7A7A;
	}
}

@media only screen and (max-width: 800px) {
	.cbo-services {
		&__txtWrp {
			text-align: center;
		}
	}
}
@media only screen and (max-width: 600px) {
	.cbo-services {
		&__imgWrp {
			max-width: none;
		}
	}
}