.cbo-main-nav {
	padding: 15px 0;
	background: #FFF;
	position: fixed;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 103px;
	box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px rgba(0,0,0,.14), 0 1px 10px rgba(0,0,0,.12);
	&__logoTxt {
		padding-left: 5px;
		transform: translateY(-10px);
		display: inline-block;
		font-family: 'Helvetica';
		font-weight: 700;
		font-size: em(16);
		color: #393939;
		transition: all 0.4s cubic-bezier(0.7,0,0.3,1);
	}
	&__logo {
		width: 33px;
		transition: all 0.4s cubic-bezier(0.7,0,0.3,1);
	}
	&__list {
		padding-left: 0;
		display: inline-block;
		margin-top: 30px;
	}
	&__item {
		display: inline-block;
		padding-right: 40px;
	}
	&__telWrp {
		margin-left: 25px;
		display: inline-block;
		&--burger {
			margin-left: 40px;
		}
	}
	&__tel {
		font-family: 'Helvetica';
		font-size: em(16);
		font-weight: 700;
		color: #3E3E3E;
	}
	&__link {
		text-decoration: none;
		font-family: 'Helvetica';
		font-weight: 400;
		font-size: em(16);
		color: #2D2D2D;
		transition: color 0.4s ease;
		&:hover {
			color: #00C1FF;
		}
	}
	&__btnWrp {
		margin-top: 25px;
		&--price {
			margin-top: 0;
		}
		&--burger {
			display: inline-block;
			margin-left: 40px;
		}
	}
	&__button {
		box-shadow: 0px 3px 0px rgba(50, 50, 50, 0.3);
		padding: 10px 25px;
		background: #FFE100;
		border-radius: 5px;
		font-family: 'Helvetica';
		font-weight: 500;
		font-size: em(16);
		color: #2D2D2D;
		text-decoration: none;
		cursor: pointer;
		&--price {
			padding: 15px 80px;
		}
		&--form {
			width: 100%;
			padding: 22px 80px;
		}
	}
	&__logoWrp {
		display: inline-block;
	}
	&__burgerWrp {
		display: inline-block;
		padding-top: 10px;
		padding-right: 50px;
	}
	&__burger {
		margin: 0 auto;
		width: 40px;
		height: 40px;
	}
	&__burgerItem {
		display: block;
		width: 38px;
		height: 3px;
		background-color: #3D3D3D;
		border-radius: 5px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 1440px) {
	.cbo-main-nav {
		&__inner {
			padding-left: 30px;
		}
	}
}
@media only screen and (max-width: 800px) {
	.cbo-main-nav {
		&__list {
			display: flex;
			position: fixed;
			left: -100%;
			top: 0;
			width: 100%;
			height: 100%;
			flex-direction: column;
			margin: 0;
			padding: 0;
			align-items: stretch;
			z-index: 9;
			background-color: #FFF;
		}
		&__item {
			flex-basis: auto;
			flex-grow: 1;
			flex-shrink: 0;
			flex-direction: column;
			padding-right: 0;
			display: flex;
			text-align: center;
			border-bottom: 1px solid rgba(128, 128, 128, 0.2);
			padding-top: 90px;
			&:last-child{
				border-bottom: none;
			}
		}
		&__link {
			font-size: 2.5em;
			flex-basis: auto;
			flex-grow: 1;
			flex-shrink: 0;
		}
	}
}
@media only screen and (max-width: 768px) {
	.cbo-main-nav {
		&__btnWrp {
			&--burger {
				margin-left: 34px;
			}
		}
		&__item {
			padding-top: 70px;
		}
	}
}
@media only screen and (max-width: 600px) {
	.cbo-main-nav {
		&__btnWrp {
			&--burger {
				display: none;
			}
		}
		&__telWrp {
			display: none;
		}
	}
}
@media only screen and (max-width: 414px) {
	.cbo-main-nav {
		&__item {
			padding-top: 50px;
		}
		&__link {
			font-size: 1.5em;
		}
	}
}
@media only screen and (max-width: 320px) {
	.cbo-main-nav {
		&__button {
			&--form {
				padding: 10px 75px;
			}
		}
	}
}