.cbo-main {
	background: url('../images/cbo-main.jpg') no-repeat;
	background-size: cover;
	width: 100%;
	height: 675px;
	padding-top: 60px;
	margin-top: 100px;
	&__left {
		margin-top: 65px;
	}
	&__title {
		line-height: 59px;
		margin: 0;
		font-family: 'Helvetica';
		font-weight: 700;
		font-size: em(46);
		color: #FFFFFF;
		text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
		padding-bottom: 40px;
	}
	&__subtitle {
		font-family: 'Helvetica';
		font-size: em(23);
		font-weight: 400;
		color: rgba(255, 255, 255, 0.65);
	}
	&__watchWrp {
		margin-top: 150px
	}
	&__watch {
		font-family: 'Helvetica';
		font-size: em(20);
		font-weight: 700;
		color: #FFFFFF;
	}
	&__arrow {
		display: inline-block;
		width: 47px;
		height: 47px;
		border-radius: 50%;
		border: 1px solid #FFF;
		margin-left: 30px;
		transform: translateY(-5px);
		color: #FFF;
		text-align: center;
		padding-top: 10px;
		font-size: em(18);
		text-decoration: none;
	}
	&__steps {
		background: #FFF;
		padding: 40px 28px 40px 73px;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		margin-bottom: 24px;
		width: 500px;
		height: 170px;
		position: relative;
		&:last-child{
			margin-bottom: 0;
		}
		&--second {
		 	padding: 30px 28px 40px 73px;
		 } 
	}
	&__stepsSubtitle {
		font-family: 'Helvetica';
		font-size: em(20);
		color: #00C1FF;
		font-weight: 700;
	}
	&__text {
		font-family: 'Helvetica';
		font-size: em(16);
		font-weight: 400;
		line-height: 25px;
		color: #3E3E3E;
	}
	&__textInner {
		text-decoration: underline;
		font-weight: 700;
	}
	&__textInnerLink {
		color: #3E3E3E;	
	}
	&__numWrp {
		text-align: center;
		position: absolute;
		left: -8%;
		top: 25%;
		border-radius: 50%;
		width: 80px;
		height: 80px;
		background: #FFE100;
		padding-top: 20px;
	}
	&__num {
		font-family: 'Helvetica';
		line-height: normal;
		font-weight: 700;
		font-size: em(35);
		color: #FFFFFF;
	}
}

@media only screen and (max-width: 1440px) {
	.cbo-main {
		&__numWrp {
			padding-top: 24px;
		}
		&__arrow {
			padding-top: 13px;
		}
	}
}

@media only screen and (max-width: 1366px) {
	.cbo-main {
		&__stepsSubtitle {
			font-size: 1.5em;
		}
		&__text {
			font-size: 1.25em;
		}
	}
}

@media only screen and (max-width: 800px) {
	.cbo-main {
		&__left {
			margin-left: 40px;
		}
		&__steps {
			width: 370px;
			padding: 30px 28px 40px 73px;
		}
		&__numWrp {
			padding-top: 26px;
		}
	}
}
@media only screen and (max-width: 600px) {
	.cbo-main {
		&__watchWrp {
			margin-top: 90px;
		}
		&__steps {
			width: 260px;
			padding: 30px;
		}
	}
}
@media only screen and (max-width: 414px) {
	.cbo-main {
		height: 1000px;
		padding-top: 20px;
		&__left {
			margin-top: 0;
			margin-left: 25px;
		}
		&__steps {
			width: 100%;
			padding: 24px;
		}
		&__right {
			margin-top: 50px;
		}
	}
}