.cbo-whyme {
	&__iconWrp {
		background: #FFDE5B;
		border-radius: 50%;
		width: 90px;
		height: 90px;
		text-align: center;
		padding-top: 20px;
		display: inline-block;
	}
	&__txtWrp {
		display: inline-block;
		max-width: 400px;
		margin-bottom: 118px;
		padding-left: 35px;
		text-align: left;
	}
	&__subtitle {
		font-family: "Helvetica";
		line-height: 25px;
		font-weight: 500;
		font-size: em(20);
	}
	&__text {
		font-family: 'Helvetica';
		line-height: 25px;
		font-size: em(16);
		color: #7A7A7A;
	}
}

@media only screen and (max-width: 414px) {
	.cbo-whyme {
		&__txtWrp {
			text-align: center;
			margin-bottom: 80px;
			padding-left: 0;
		}
	}
}