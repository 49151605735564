.cbo-steps {
	background: #05BDF7;
	padding-top: 80px;
	padding-bottom: 100px;
	&__title {
		font-family: 'Helvetica';
		font-size: em(38);
		color: #FFF;
		font-weight: 700;
		margin-bottom: 30px;
	}
	&__subtitle {
		font-family: 'Helvetica';
		font-size: em(18);
		color: #FFF;
		font-weight: 400;
	}
	&__steps {
		display: inline-block;
		background: #FFF;
		width: 340px;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		padding: 60px 0 40px 60px;
		text-align: left;
		position: relative;
		margin-right: 20px;
		margin-top: 60px;
		&:last-child {
			margin-right: 0;
		}
	}
	&__numWrp {
		position: absolute;
		width: 80px;
		height: 80px;
		background: #FFE100;
		border-radius: 50%;
		text-align: center;
		padding-top: 20px;
		left: -15%;
		top: 35%;
	}
	&__num {
		font-family: 'Helvetica';
		line-height: normal;
		font-weight: 700;
		font-size: em(35);
		color: #FFFFFF;
	}
	&__stepSubtitle {
		font-family: 'Helvetica';
		line-height: 33px;
		font-size: em(19);
		color: #00C1FF;
		font-weight: 700;
	}
	&__text {
		font-family: 'Helvetica';
		line-height: 25px;
		font-size: em(16);
		color: #3E3E3E;
		max-width: 235px;
	}
}

@media only screen and (max-width: 1440px) {
	.cbo-steps {
		&__text {
			font-size: 1.2em;
		}
		&__stepSubtitle {
			font-size: 1.5em;
		}
		&__numWrp {
			padding-top: 24px;
		}
	}
}

@media only screen and (max-width: 414px) {
	.cbo-steps {
		&__numWrp {
			width: 60px;
			height: 60px;
			left: -10%;
			padding-top: 16px;
		}
		&__steps {
			width: 100%;
		    padding: 20px 0 20px 45px;
		}
	}
}
@media only screen and (max-width: 375px) {
	.cbo-steps {
		&__steps {
			width: 310px;
		}
	}
}
@media only screen and (max-width: 320px) {
	.cbo-steps {
		&__steps {
			width: 260px;
		}
	}
}